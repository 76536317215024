/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-turn-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 8.5A2.5 2.5 0 017.5 6H9V4.534a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 019 8.466V7H7.5A1.5 1.5 0 006 8.5V11H5z"/><path pid="1" fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 010-2.098zm1.4.7a.495.495 0 00-.7 0L1.134 7.65a.495.495 0 000 .7l6.516 6.516a.495.495 0 00.7 0l6.516-6.516a.495.495 0 000-.7L8.35 1.134z"/>',
    },
});
